@font-face {
  font-family: 'Noto Sans';
  src: url('./Assets/fonts/NotoSans-BoldItalic.woff2') format('woff2'),
    url('./Assets/fonts/NotoSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./Assets/fonts/NotoSans.woff2') format('woff2'),
    url('./Assets/fonts/NotoSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./Assets/fonts/NotoSans-Italic.woff2') format('woff2'),
    url('./Assets/fonts/NotoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./Assets/fonts/NotoSans-Bold.woff2') format('woff2'),
    url('./Assets/fonts/NotoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Impact';
  src: url('./Assets/fonts/Impact.woff2') format('woff2'),
    url('./Assets/fonts/Impact.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;

}

body {
  font-family: 'Noto Sans';
  color: #9E9E9E;
  background-color: #0A0A0C;
}

.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}

footer {
  position: relative;
  z-index: 9;
}

.wrapper {
  overflow-x: hidden;
}
a.sbutn {
  background: #89cff0;
  width: 100%;
  display: table;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  color: #000;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 10px;
}

.hero-sec ul.tbtns,.hero-sec ul.tbtns li {
  width: 100%;
}

a.sbutn:hover,a.sbutn:focus {
  background: rgb(137 207 240 / 90%);
}
section.about-tabs ul li.active a,
section.about-tabs ul li:hover a {
  color: #89CFF0;
}

.mobile-menu {
  display: none;
}

.privacy-content {
  padding: 5% 0;
}

section.playsect.rewardsm {
  background: url(Assets/Images/rewardsbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 15% 0;
}

.rewardsm .col h3 {
  margin-bottom: 10px;
}

.playsect.rewardsm:before {
  z-index: 9;
  width: 550px;
  height: 380px;
  background-size: cover;
}

.rewardsm .col img {
  margin-bottom: 20px;
}
.rewardsm .col{
z-index: 99;
}
.playsect.rewardsm:after {
  z-index: 9;
  width: 450px;
  height: 217px;
  background-size: cover;
}

.rewardsm .col {
  background: rgb(43 43 46 / 50%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.rewardsm .col p {
  text-align: left;
}

.rewardsm .col .btnn {
  width: 160px;
}

.privacy-content a {
  color: #fff;
}

.privacy-content-list ul {
  padding-left: 45px;
  padding-top: 14px;
}

.privacy-content h3 {
  margin-bottom: 20px;
}

.privacy-content h2 {
  font-size: 70px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}

.privacy-content-list {
  margin-bottom: 30px;
}

.privacy-content-list ul li {
  margin-bottom: 10px;
}

a,
li {
  text-decoration: none;
  list-style: none;

}

p {
  line-height: 1.7;
}

section.hero-sec {
  padding: 0px 0 0 11%;
}

form.newsleter {
  width: 80%;
  display: flex;
  margin-bottom: 10px;
  position: relative;
  flex-direction: column;

}

form.newsleter input {
  padding: 12px 10px;
  background: #2b2b2e;
  font-size: 13px;
  border: 0;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Noto Sans';
  position: relative;
  color: #9E9E9E;
}

form.newsleter button.btnn.prebtn {
  width: 128px;
  background-color: transparent;
  border: 0;
  margin: auto;
  font-size: 14px;
  right: 6px;
  cursor: pointer;
  margin-left: 0;
  margin-top: -3px;
}

header.head {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  background: #17171A;
  align-items: center;
  border-bottom: 1px solid #363636;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
}

a.redicle {
  width: 40px;
  display: table;
  margin-right: 10px;
}

a.redicle img {
  width: 100%;
  transition: all 0.5s ease;

}

a.redicle:hover img {
  filter: invert(100%) sepia(1%) saturate(13%) hue-rotate(302deg) brightness(222%) contrast(166%);
}

header.head .rightnav ul {
  display: flex;
  justify-content: flex-end;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

header.head .rightnav ul li .btnn {
  width: 124px;
  background-size: 100% 100%;
}

.rightnav ul.icon {
  align-items: center;
  justify-content: center;
}

header.head .rightnav ul.icon li:nth-child(2n) {
  width: auto;
  border: 0;
  padding: 0;
}

.rightnav ul.icon {
  justify-content: center;
}

header.head .rightnav ul.icon li a {
  line-height: initial;
}

.rightnav {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

div#mainwrapper {
  position: relative;
  z-index: 9;
}

.maindiv {
  float: right;
  width: 96%;
  margin-top: 79px;

}

header.head .rightnav ul li {
  padding: 0px 19px;
  list-style: none;
  display: flex;
  align-items: center;
}

header.head .rightnav ul li a {
  margin-right: 0;
}

header.head .rightnav ul li {
  padding: 0 12px;
}

header.head a.logodiv {
  /* width: 20%; */
  margin-right: auto;
}

header.head a.logodiv img {
  width: 100%;
  max-width: 125px;
}

/* header.head .rightnav ul li:first-child {
  border-right: 1px solid #363636;
} */

header.head .rightnav ul li a {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  text-decoration: none;
}

header.head .rightnav ul li a.translator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

header.head .rightnav ul li a.translator img {
  width: 100%;
  max-width: 20px;
  margin: 0 9px;
}

.btnn {
  width: 100%;
  background-image: url(Assets/Images/activebtn.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all .5s ease;
  top: 0;
  text-align: center;
  padding: 7.6px 3px !important;
  margin: 0 30px 0 auto;
  color: #000;
}

.btnn:hover {
  background-image: url(Assets/Images/hoverbtn.png);
}

.btnn:active,
.btnn:focus {
  background-image: url(Assets/Images/focusbtn.png);
}

header.head .rightnav ul li:nth-child(2n) {
  width: auto;
  border-left: 1px solid;
  padding-left: 10px;
  padding-right: 0;
}

section.comingsoon {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

section.comingsoon h1 {
  font-size: 60px;
  text-transform: uppercase;
}

.Language ul {
  padding: 0;
  flex-direction: column;
  justify-content: space-evenly !important;
  background: #2B2B2E;
  position: absolute;
  top: 8.3%;
  right: 8.5%;
  width: 116px;
  height: 200px;
  display: none !important;
}

.heordetail h1 span {
  color: #fff;
}

.Language ul li button {
  background: no-repeat;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #9E9E9E;
  text-transform: uppercase;
  transition: all 0.5s ease;
  cursor: pointer;
  width: 100%;
  text-align: start;
  position: relative;
}

section.zonevideo a.btnn.prebtn {
  margin: auto;
}

.Language ul li button:hover,
.clicklink ul li a:hover,
footer .soicallinks a:hover {
  color: #89CFF0;
}

.Language ul li button.active:before {
  content: '';
  position: absolute;
  width: 29%;
  height: 100%;
  right: 0;
  background-image: url(Assets/Images/check2.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  margin: auto;
}

.modal-btn {
  margin: 0 auto;
  display: block;
  outline: none;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 16px;
  padding: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.modal-btn:hover {
  color: #ccc;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0px auto;
  z-index: 999;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.85);
  filter: blur(30px);
}

.fxdimg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  right: 0;
  margin: auto;
  bottom: 0;
  padding: 42px 0;
  background: #0A0A0C;
  box-sizing: border-box;
}

.fxdimg img {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  margin: auto;
  overflow-y: auto;
}

.fxdimg button {
  position: absolute;
  right: 30px;
  bottom: 30px;
  outline: none;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 25px;
  padding: 0;
  line-height: 1;
  font-family: cursive;
  font-weight: bold;
  z-index: 9999;
  width: 20px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(30 30 31);
}

::-webkit-scrollbar-thumb {
  background-color: #89cff0;
  outline: 1px solid #17171a;
}

sidebar {
  position: fixed;
  width: 4%;
  height: 100vh;
  background: #17171A;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  border-right: 1px solid #363636;
  z-index: 99;
  overflow-y: auto;
}

sidebar .logsect ul {
  padding: 0;
}

sidebar .container {
  margin-top: 88px;
  transition: all 0.5s ease;
}

sidebar .logsect ul li a {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #9E9E9E;
  text-transform: uppercase;
  padding: 10px 0;
  width: 80%;
  margin: 10px auto 0 auto;
  border-radius: 12px;
  position: relative;
  text-align: center;
}

sidebar .logsect ul li img {
  width: 100%;
  max-width: 50%;
  padding: 0 0 4px 0;
}

sidebar .logsect ul li a:before,
sidebar .logsect ul li.active a:before {
  background: #2B2B2E;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
  border-radius: 12px;
  transition: all 0.5s ease;
}

sidebar .logsect ul li.active a:before {
  width: 100%;
  height: 100%;
}

sidebar .logsect ul li a:hover,
sidebar .logsect ul li.active a {
  font-weight: bold;
  color: #fff;
}

sidebar .logsect ul li:hover a img,
sidebar .logsect ul li.active a img {
  filter: invert(81%) sepia(8%) saturate(2595%) hue-rotate(169deg) brightness(175%) contrast(90%)
}

footer {
  width: 100%;
  background: #17171A;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1417px;
  margin-left: auto;
  margin-right: auto;
}

footer .container {
  display: flex;
  flex-wrap: wrap;
}

footer .logosec {
  WIDTH: 23%;
}

footer .logsect {
  width: 32%;
}

footer .soicallinks {
  width: 25%;
}

.prowedicon {
  width: 17%;
}

footer h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #9E9E9E;
  margin: 0 0 10px 0;
}

ul {
  margin: 0;
}

footer .logsect ul {
  width: 30%;
  padding: 0;
}

.clicklink {
  display: flex;
}

.clicklink ul li {
  padding: 0 0 8px 0;
}

.clicklink ul li a,
footer .soicallinks a {
  color: #9E9E9E;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.clicklink ul {
  width: 33% !important;
}

ul.icon li img {
  transition: all 0.5s ease;
}

ul.icon li:hover img {
  filter: invert(51%) sepia(3%) saturate(11%) hue-rotate(52deg) brightness(170%) contrast(179%);
}

footer .soicallinks ul.icon {
  padding: 0px;
  display: flex;
  justify-content: start;
  margin-bottom: 10px;

}

.tokenrow .tokenitem img {
  transition: all 0.5s ease;
}

.tokenrow .tokenitem:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.tokenrow .tokenitem {
  top: 0;
  position: relative;
  transition: all 0.5s ease;
}

.tokenrow .tokenitem:hover {
  top: -6px;
}

div#mainwrapper .giland .columns2 {
  position: relative;
  z-index: 99;
}

footer .soicallinks ul.icon li {
  padding: 0 5px;
}

footer .soicallinks ul.icon a {
  color: #000;
}

footer .logosec p {
  padding: 0 20px 30px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #9E9E9E;
}

section.hero-sec {
  width: 100%;
  background: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 0;
}

section.hero-sec .container {
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
}

.mapcont {
  padding: 5% 0;
}

.mapcont img {
  width: 100%;
}

section.hero-sec:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(45deg, black, #0000);
  z-index: 0;
}
.hero-sec .heordetail {
  width: 340px;
  padding: 20px 18px;

}
.hero-sec .heordetail p {
  margin-bottom: 0;
font-size: 15px;

}
.heordetail p {
  font-size: 16px;
}
.heordetail {
  background-image: url(Assets/Images/heroshap.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 40px 50px;
  box-sizing: border-box;
}

.heordetail h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  color: #89CFF0;
  text-transform: uppercase;
}
.homepage section.dicsect img {
  width: 100%;
}

.abustsec {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.abustsec .col {
  width: 30%;
  flex: 0 0 30%;
}

.abustsec .col-l {
  width: 60%;
  flex: 0 0 60%;
}

.abustsec .col-l img {
  width: 100%;
}
footer .logosec p:last-child {
  padding-bottom: 0;
}

footer {
  padding: 40px 0;
}

ul.tbtns {
  padding: 0;
  display: flex;
  align-items: center;
}

.btnn.prebtn,
.btnn.secndbtn {
  width: 200px;
  height: 67px;
}

.btnn {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.secndbtn {
  background-image: url(Assets/Images/secondrybtn.png);
  color: #89CFF0;

}

.secndbtn:hover {
  background-image: url(Assets/Images/secondryhver.png);

}

.secndbtn:active,
.secndbtn:focus {
  background-image: url(Assets/Images/secondryactive.png);

}

.columns2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.columns2 .col {
  width: 48%;
  flex: 0 0 40%;
  box-sizing: border-box;

}

.plybxes {
  background: url(Assets/Images/plybxbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

section.playsect {
  padding: 10% 0;
  position: relative;
}

section.playsect:before {
  background: url(Assets/Images/aboutshaps.png);
  content: '';
  background-position: top left;
  height: 700px;
  width: 815px;
  position: absolute;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;

}

section.playsect:after {
  background: url(Assets/Images/rightshap.png);
  content: '';
  background-position: bottom right;
  height: 700px;
  width: 815px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-repeat: no-repeat;

}

.plybxes {
  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 0 35px 0;
  height: 100%;
  min-height: 365px;
  position: relative;
  top: 0;
  text-align: center;

  transition: all 0.5s ease;
}

h3 {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
}

.btnn.centerbtn {
  margin: auto;
}

.plybxes p {
  margin: 0;
}

.plybxes h3 {
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 24px;
}

.plybxes:hover {
  top: -6px;
}

.plybxes:hover img {
  transform: scale(1.1);
}

.plybxes img {
  transition: all 0.5s ease;
}

.headings {
  display: table;
  margin: auto;
  position: relative;
  text-align: center;
  margin-bottom: 35px;
  width: 100%;

}

.headings h2 {
  font-size: 300px;
  -webkit-text-fill-color: #ffffff00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255 255 255 / 0.10);
  line-height: 1;
}

.headings h3 {
  position: absolute;
  top: 30px;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  height: max-content;
}

ul {
  padding: 0;
}

.columns2 .cols {
  width: 50%;
  flex: 0 0 50%;
}

section.alphasect {
  padding: 9% 0px 0;
  background: url(Assets/Images/bg1.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.alphatext {
  padding-bottom: 8%;
}

.alphaimg img {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 50%;
  bottom: 0px;
}

.alphatext ul {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 45px;
  padding-left: 40px;
  position: relative;
}

.alphatext ul li {
  margin-bottom: 35px;
  position: relative;
}

.alphatext ul li:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #89CFF0;
  position: absolute;
  left: -34px;
  top: 2px;
  border-radius: 50px;
  bottom: 0;
  margin: auto;
}

h4 {
  font-size: 24px;
  color: #fff;
  margin-top: 35px;
}

h3.textcenter {
  text-align: center;
}

section.partners {
  padding: 5% 0;
  position: relative;
}

.partners-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.partners-logo img {
  margin: 17px;
  position: relative;
  top: 0;
  transition: all 0.5s ease;
}


.partners-logo img:hover {
  filter: invert(70%) sepia(3%) saturate(11%) hue-rotate(52deg) brightness(165%) contrast(132%);
  top: 10px;
}

section.partners h3 {
  margin-bottom: 20px;
}

section.partners:before {
  background: url(Assets/Images/partnerbefore.png);
  content: '';
  background-position: top left;
  height: 700px;
  width: 815px;
  position: absolute;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;

}

section.partners:after {
  background: url(Assets/Images/partnerafter.png);
  content: '';
  background-position: bottom right;
  height: 700px;
  width: 815px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-repeat: no-repeat;

}

.columns2.colwdth .col {
  flex: 0 0 50%;
  width: 50%;
}

.columns2.colwdth .col img {
  width: 100%;
}

.textpadl {
  padding-right: 15%;
  box-sizing: border-box;
}

.textpadl P {
  margin: 35px 0;
}

#mainwrapper .tokendicsect {
  padding: 6% 0;
}

.homepage section.dicsect {
  position: relative;
  overflow: hidden;
  padding: 40px 0;
}

.btnn.textleft {
  margin-right: auto;
  text-align: left;
  margin-left: 0;
}

.gaiatoksect {
  background: url(Assets/Images/bg3.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.gaiatoksect .col img {
  margin-top: -105px;
}

section.joinsect {
  background: url(Assets/Images/bg5.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.joinsect .columns2.colwdth .col img {
  width: 826px;
  margin-left: -55px;
}

ul.icon.sclfixed {
  position: fixed;
  bottom: 20px;
  z-index: 999;
  right: 32px;
  display: none;
  flex-wrap: wrap;
  background: url(Assets/Images/sclbg.png);
  padding: 24px;
  box-sizing: border-box;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
}

ul.icon.sclfixed li {
  margin: 0 10px;
}

.hero-sec video {
  height: 480px;
  object-fit: cover;
}

.testimonal {
  width: 100%;
  background: url(Assets/Images/bg4.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0 0 0;
}

.monstor-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.slider-left-img {
  width: 50%;
  text-align: start;
}

.monstor-content {
  position: absolute;
  width: 62%;
  left: 0%;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 99;
  top: 0;
  bottom: 0;
  height: 50%;
}

.slider-left-img.right-side {
  text-align: end;
}

.monstor-content h3 {
  margin: 0;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.monstor-content h2 {
  font-size: 140px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  line-height: 160px;
  padding: 3.5% 0 5% 0;
  color: #fff;
}

.monstor-content p {
  color: #9E9E9E;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 0 24%;
}

.slider-left-img.right-side img {
  margin-left: auto;
}

.slider-left-img img,
.slider-left-img.right-side img {
  width: 100%;
}

button.slick-arrow.slick-prev {
  left: 0;
  right: 70px;
  margin: auto;
  top: 152px;
  z-index: 99;
  bottom: 0;
}

button.slick-arrow.slick-next {
  right: 0;
  left: 70px;
  margin: auto;
  top: 152px;
  bottom: 0;
}

.slick-prev:before {
  content: url('Assets/Images/leftarrow.svg');
}

.slick-next:before {
  content: url('Assets/Images/rightarrow.svg');
}

.slick-next:hover:before,
.slick-prev:hover:before {
  filter: invert(78%) sepia(96%) saturate(2788%) hue-rotate(168deg) brightness(144%) contrast(88%);
}

.desknone {
  display: none;
}

.mobile-menu-icon {
  display: none;
}

/* about us */
.aboutplay .abtplay {
  width: 31.9%;
  flex: 0 0 31.9%;
  margin: 20px 10px;
  text-align: center;
}

.abtplay {
  background-image: url(Assets/Images/aboutplay.png);
}

.aboutplay::before,
.aboutplay::after {
  width: 0 !important;
}

div#mainwrapper .innerbanner {
  background-image: url(Assets/Images/innerbane.png);
  height: 800px;
}

.innercontent h1 {
  font-size: 140px;
  font-family: 'Impact';

  line-height: 160px;
  color: #fff;
  font-weight: 400;
}

div#mainwrapper .innerbanner .innercontent {
  background: none;
  height: initial;
  width: 57%;
}

div#mainwrapper .innerbanner .innercontent p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

section.about-tabs {
  background: #17171a;
  width: 100%;
}

section.about-tabs ul {
  width: 76%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

section.about-tabs ul li a {
  width: 100%;
  text-align: center;
  padding: 21px 0;
  color: #9e9e9e;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  position: relative;
  display: block;
  z-index: 9;
}

section.about-tabs ul li {
  width: 100%;
}

section.about-tabs ul li a:before {
  content: '';
  position: absolute;
  width: 0;
  background: #89cff0;
  left: 0;
  height: 5px;
  bottom: 0;
  transition: all 0.5s ease;
  z-index: 9;
}

section.about-tabs ul li a:hover:before,
section.about-tabs ul li.active a:before {
  width: 100%;
}

.colreverse {
  margin-left: auto;
  box-sizing: border-box;
}

section.dicsect video {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

section.dicsect .container .colreverse {
  height: 100%;
  display: flex;
  align-items: center;
}

section.dicsect .container {
  position: relative;
  z-index: 9;
  height: 100%;
}

.dicsect .heordetail {
  box-sizing: border-box;
  margin-left: auto;
}

.colreverse .col.textpadl {
  padding-right: 5%;
  padding-left: 8%;
}

section.about-tabs ul li a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  background: #363636;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transition: all 0.5s ease;
  z-index: -1;
}

section.about-tabs ul li a:hover:after,
section.about-tabs ul li.active a:after {
  height: 100%;
  transition-delay: 0.4s;
}

div#mainwrapper .aboutplay {
  padding: 1.8% 0;
}

section.serlearn {
  width: 100%;
  padding: 11% 0;
  text-align: center;
  position: relative;
  z-index: 9;
}

section.serlearn .columns2 {
  padding: 4% 0 0 0;
}

.serimg {
  width: 35%;
  flex: 0 0 32.4%;
  margin: 22px 5px;
  text-align: center;
}

.serimg img {
  width: 100%;
  transition: all 0.5s ease;
}

.serimg:hover img {
  transform: translateY(-10px);
}

section.serlearn::before,
section.buytoken:before,
div#mainwrapper .giland:before,
.blogloader:before {
  content: '';
  position: absolute;
  width: 50% !important;
  background-image: url(Assets/Images/aboutshaps.png);
  height: 70%;
  left: 0;
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

section.roadmap {
  width: 100%;
  text-align: center;
  position: relative;
  padding: 5% 0;
}

section.roadmap::before,
.buygaia:before,
div#mainwrapper .giland:after {
  content: '';
  position: absolute;
  background-image: url('Assets/Images/sideblue.png');
  width: 100% !important;
  height: 100%;
  left: 0;
  background-repeat: no-repeat;
  background-position: left;
  top: -20%;
  background-size: contain;
  z-index: -1;
}

.map-inline-list {
  width: 100%;
}

.map-list {
  position: relative;
  padding: 50px 0;
}

.map-list:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 92%;
  background: #2b2b2e;
  top: 5%;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

.map-inline-list .btnn.secndbtn {
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}

.maprow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: start;
  padding: 50px 0;
}

.mapicon,
.map-content {
  width: 47.5%;
  display: flex;
  align-items: center;
  text-align: start;
}

.dots {
  width: 1%;
  background: #fff;
  height: 15px;
  border-radius: 30px;
  position: relative;
  z-index: 9;
  margin: 15px 0 0 2px;
}

.mapicon {
  justify-content: flex-end;
  padding: 7px 0 0 0;
  margin: 0 -20px 0 20px;
}

.mapicon img {
  width: 100%;
  max-width: 19px;
  margin: 0px 10px;
}

.mapicon span {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
}


.map-content {
  flex-wrap: wrap;
}

.map-content p {
  margin: 3px 0;
}

.map-content {
  padding: 10px 20px;
  box-sizing: border-box;
  background: #2b2b2e;
  border: 1px solid #363636;
  position: relative;
  transition: all 0.5s ease;
  flex-wrap: wrap;
}

.map-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
  width: 100%;
}

.map-content:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 16px solid #2b2b2e;
  top: 9px;
  left: -15px;
  transition: all 0.5s ease;
}

.revecs {
  flex-direction: row-reverse;
}

.revecs .mapicon {
  justify-content: flex-start;
  margin: 0 20px 0 -20px;
}

.simpldot {
  justify-content: center;
  padding: 60px 0 0 0;
}

section.ourteam {
  width: 100%;
  text-align: center;
  padding: 40px 0 100px 0px;
  position: relative;
}

.teamlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 0;
}

.profile {
  width: 100%;
}

.profile img {
  width: 100%;
  max-width: 40%;
}

.teaminfo h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 20px 0 0 0;
}

.teaminfo p {
  margin: 0;
  color: #9e9e9e;
  font-size: 16px;
}

.teammeme:hover {
  transform: translateY(-10px);
}

.teammeme {
  padding: 30px 0;
  width: 31%;
  margin: 0 10px;
  transition: all 0.5s ease;
  position: relative;
}

.revecs .map-content::before {
  right: -15px;
  left: initial;
  border-right: 0;
  border-left: 16px solid #2b2b2e;
}

.light .mapicon {
  opacity: 0.6;
}

.maprow:hover .dots {
  background: #89cff0;
}

.maprow:hover .map-content {
  background: #434347;
}

.maprow:hover .map-content:before {
  border-right-color: #434347;
  border-left-color: #434347;
}

section.ourteam:before,
.buygaia:after,
section.zonevideo::before {
  content: '';
  position: absolute;
  width: 50% !important;
  height: 100%;
  background-image: url(Assets/Images/sideblueq.png);
  bottom: 50%;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  z-index: -2;
}

section.zonevideo::before {
  bottom: -27%;
  width: 60% !important;
  background-size: auto;
}

section.ourteam:after,
section.buytoken::after,
.blogcontent::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 56%;
  background-image: url(Assets/Images/rightshap.png);
  bottom: -8%;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  z-index: -2;
}

#mainwrapper .tokendicsect {
  background-image: url(Assets/Images/bg6.png);
}

section.buytoken {
  width: 100%;
  position: relative;
  padding: 10% 0;
  overflow-y: hidden;
}

.buygaia {
  width: 100%;
  text-align: center;
  padding: 0 8%;
  box-sizing: border-box;
}

.buygaia p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  margin: 30px 0;
}

.tokenrow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  z-index: 9;
}

.tokenrow .tokenitem {
  background-image: url(Assets/Images/buy.png);
  width: 47%;
  flex: 0 0 47%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 10px;
  box-sizing: border-box;
  padding: 19px 0;
}

.buycontent {
  width: 100%;
  padding: 11% 0;
  position: relative;
  z-index: 9;
}

.buycontent h3 {
  text-align: center;
}

.buycontent p {
  color: #9E9E9E;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.buylist {
  padding: 60px 0;
}

.buylist ul.buyitem {
  display: flex;
  justify-content: space-between;
  background: #2B2B2E;
  padding: 21.5px 60px;
}

.buylist ul.buyitem:nth-child(even) {
  background: rgb(43 43 46 / 42%);
}

.buydetail ul li {
  padding: 14px 0 14px 30px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.buydetail ul li:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #89CFF0;
  left: 0;
  border-radius: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.buydetail ul p {
  padding: 30px 0 0 0;
}

.buygaia:before {
  top: 0;
  z-index: -1;
}

section.buytoken:before {
  z-index: 9;
  top: -50px;
  background-position: 40%;
  width: 70%;
  height: 51%;
}

.buygaia:after {
  bottom: 0;
  top: 0;
  margin: auto;
}

section.buytoken::after {
  bottom: -25%;
}


section.gaiamap h3 {
  text-align: center;
}

section.gaiamap {
  padding: 2.5% 0 0 0;
}

.mapimages {
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
}

.buylist ul.buyitem li {
  width: 50%;
  text-align: start;
}

#mainwrapper .gaiabg {
  background-image: url(Assets/Images/bg7.png);
}

.giland .columns2~p {
  margin: 60px 0;
  font-size: 16px;
  line-height: 30px;
}

.giland li .btnn.prebtn {
  margin-left: auto;
  margin-right: auto;
}

section.zonevideo h3 {
  text-align: center;
  padding: 0 0 30px 0;
}

.gaig-video {
  height: 550px;
  width: 999px;
  margin: 0 auto;
}

section.zonevideo p {
  padding: 50px 0;
  font-size: 15.6px;
}

section.zonevideo {
  padding: 7% 0;
}

.blogitem {
  background-image: url(Assets/Images/blogbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  text-align: start;
  padding: 0 0 20px 0;
  flex: 0 0 32%;
  width: 32%;

}

.featuredimg {
  height: 250px;
  width: 100%;
  margin: 0px auto;
  padding: 11px 11px 0px 11px;
  box-sizing: border-box;
}

.blogcontent {
  padding: 0 30px;
}

.featuredimg img {
  height: 100%;
}

.blogcontent h4 {
  font-size: 16px;
  color: #89CFF0;
  font-weight: bold;
  font-family: 'Noto Sans';
}

.blogcontent p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 30px;
  max-height: 101px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 400;
}

.blogcontent p figure {
  display: none;
}

.blogitem:hover .featuredimg img {
  transform: translateY(-5px);
}

.blogcontent .btnn.prebtn {
  margin-left: 0;
  width: 170px;
}

section.gaiablog h3 {
  text-align: center;
}

section.gaiablog .btnn.secndbtn {
  margin-right: auto;
  margin-bottom: 11%;
  margin-top: 1%;
  margin-left: auto;
  background-color: transparent;
  box-shadow: none;
  border: none;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

div#mainwrapper .giland {
  padding: 7% 0;
  text-align: center;
  position: relative;
}

div#mainwrapper .giland .container>h3 {
  padding: 0 0 20px 0;
}

div#mainwrapper .giland:before {
  z-index: 99;
}

section.zonevideo::before {
  bottom: -20%;
  width: 60% !important;
  background-size: auto;
}

.blogcontent::after {
  bottom: 0;
  height: 13%;
  opacity: 0.4;
}

.gaialand {
  height: 100%;
  overflow-y: hidden;
}

.blogloader {
  padding: 8.5% 0 0 0;
  position: relative;
}

.blogloader:before {
  height: 18%;
  top: 0;
  background-size: contain;
}

.mbilevideo {
  display: none;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .rewardsm .columns2 .col {
    width: 90%;
    flex: 0 0 90%;
    margin: 0 auto 28px;
  }

  .rewardsm .col img {
    width: 55%;
  }

  .playsect.rewardsm:after {
    bottom: 0px;
  }
  .playsect.rewardsm:before,.playsect.rewardsm:after {
      width: 325px;
      height: 203px;
  }

  section.about-tabs.fixed-tabs {
    position: fixed;
    top: 79px;
    width: 100%;
    z-index: 999;
  }

  .dsktpvideo {
    display: none;
  }

  .mbilevideo {
    display: block;
  }

  section.dicsect .container .colreverse {
    height: auto;
  }

  section.dicsect {
    height: auto;
  }

  section.dicsect video {
    position: initial;
  }

  .maprow:hover .map-content:before {
    border-bottom-color: #434347;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  section.dicsect ul.tbtns {
    flex-wrap: nowrap;
  }

  sidebar .container {
    margin-top: 26px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

  }

  .logsect {
    width: 100%;
  }

  .heordetail h1 {
    font-size: 24px;
    line-height: 1.2;
  }

  .hero-sec video {
    object-fit: cover;
    height: 425px;
  }

  section.playsect {
    padding: 15% 0 20% 0;
  }
  .abustsec .col-l ,  .abustsec .col{
    width: 100%;
    flex: 0 0 100%;
}

.homepage section.dicsect {
    padding: 40px 0 0;
}
  section.playsect:after {
    width: 110%;
    height: 40%;
    background-size: contain;
    bottom: -100px;
  }

  section.dicsect ul.tbtns li {
    margin-right: 0;
    width: 50%;
  }

  .partners-logo {
    justify-content: space-between;
  }

  section.dicsect .col.textpadl,
  section.joinsect .col.textpadl {
    text-align: start;
  }


  .gaig-video {
    width: 100%;
    height: 240px;
  }

  .gaialand {
    overflow: visible;
  }

  section.gaiablog .btnn.secndbtn {
    width: 100%;
    background-size: contain;
  }

  section.gaiablog .serimg {
    flex: 0 0 100%;
    margin: 15px 0;
    height: auto;
    padding-bottom: 25px;
  }

  .featuredimg img {
    height: auto;
  }

  .featuredimg {
    height: auto;
  }

  .blogcontent h4 {
    margin-top: 15px;
  }

  section.zonevideo p {
    padding-top: 20px;
  }

  .blogcontent::after {
    bottom: -350px;
  }

  footer {
    position: relative;
    z-index: 9;
  }

  .profile img {
    max-width: 100%;
  }

  .teamlist {
    justify-content: space-between;
  }

  #mainwrapper .tokendicsect .columns2.colwdth {
    flex-direction: column-reverse;
  }

  #mainwrapper .tokendicsect .col.textpadl {
    text-align: start;
  }

  .textpadl P {
    font-size: 16px;
  }

  section.serlearn::before,
  section.buytoken:before,
  div#mainwrapper .giland:before,
  .blogloader:before {
    width: 100% !important;
    background-size: cover;
    height: 10%;
    left: 0;
    top: 0;
  }

  .buygaia {
    padding: 0;
  }

  .tokenrow .tokenitem {
    width: 100%;
    flex: 0 0 100%;
  }

  .tokenrow .tokenitem img {
    width: 60%;
  }

  .buygaia p {
    margin-bottom: 0;
  }

  .buygaia:before,
  div#mainwrapper .giland:after {
    display: none;
  }

  .buylist ul.buyitem {
    padding: 14px 20px;
    flex-direction: column;
  }

  .buylist ul.buyitem li {
    padding: 7px 0;
    color: #fff;
    width: 100%;
  }

  .buydetail ul li {
    line-height: 40px;
    padding: 0px 0 40px 20px;
    font-size: 15px;
  }

  .buydetail ul li:before {
    bottom: initial;
    top: 15px;
  }

  .buydetail ul p {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  section.gaiamap .container {
    display: none;
  }

  .mapimages img {
    width: 100%;
  }

  section.ourteam:after,
  section.buytoken::after,
  .blogcontent::after {
    width: 80%;
  }

  section.ourteam:before {
    bottom: 0;
    width: 100%;
    height: 600px;
  }

  section.roadmap:before {
    height: 560px;
    top: 0;
  }

  #mainwrapper .tokendicsect ul.tbtns li {
    width: 50%;
  }

  section.about-tabs ul {
    width: 100%;
    border-top: 2px solid #363636;
  }

  section.about-tabs .container {
    padding: 0px;
  }

  .aboutplay .abtplay {
    flex: 0 0 100%;
    margin: 20px auto;
  }

  .plybxes p {
    font-size: 13px;
  }

  .serimg {
    flex: 0 0 90%;
    margin: 5px auto;
  }

  .map-content p {
    font-size: 16px;
    line-height: 40px;
  }

  .dots {
    height: 8px;
    width: 2%;
    position: absolute;
    top: -3%;
    right: 0;
    left: 0;
    margin: auto;
  }

  .mapicon {
    margin: 0;
    padding: 0;
  }

  .map-list:before {
    height: 0;
  }

  div#mainwrapper .innerbanner .innercontent {
    width: 100%;
    margin: 0;
    padding: 0 0;
  }

  div#mainwrapper .innerbanner .innercontent p {
    color: #fff;
    font-size: 15px;
    line-height: 30px;
  }

  .innercontent h1 {
    font-size: 75px;
    line-height: 80px;
  }

  div#mainwrapper .innerbanner {
    height: 630px;
    background-position: 53%;
  }

  section.about-tabs ul li a {
    font-size: 10px;
    padding: 11px 2px;
  }

  .revecs .mapicon {
    margin: 0;
    justify-content: center;
  }

  .teammeme {
    width: 47%;
    margin: 0;
    padding: 0 0 30px;
  }

  .profile img {
    max-width: 100%;
  }

  section.ourteam {
    padding: 40px 0 0px 0px;
  }

  section.ourteam:after {
    bottom: -14%;
    height: 625px;
    width: 100%;
  }

  .teaminfo h3 {
    padding-top: 4px;
  }

  .teamlist {
    justify-content: space-between;
  }

  .teaminfo h3 {
    font-size: 13px;
  }

  .teaminfo p {
    font-size: 13px;
  }

  .map-content:before {
    border-bottom: 19px solid #2b2b2e;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    left: 0;
    right: 0;
    top: -23px;
    margin: auto;
  }

  .revecs .map-content::before {
    right: 0;
    border-bottom: 19px solid #2b2b2e;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    left: 0;
  }


  section.about-tabs ul::-webkit-scrollbar {
    height: 0px;
  }

  .map-content {
    padding: 0 10px;
  }


  section.hero-sec:before {
    background: #000000b5;
  }

  section.about-tabs ul li a:before {
    top: -2px;
    height: 2px;
  }

  /* section.serlearn {
    display: none;
} */

  section.roadmap {
    padding: 17% 0 0 0;
  }

  .maprow {
    flex-direction: column;
    position: relative;
    padding: 0;
  }

  .mapicon,
  .map-content {
    width: 100%;
    justify-content: center;
  }

  .mapicon {
    padding: 10px 0 20px 0px;
    margin: 0 0 0 -14px;
  }

  .dots:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 44px;
    background: #2B2B2E;
    top: -44px;
    left: 0;
    right: 0;
    margin: auto;
  }

  section.roadmap .map-inline-list .btnn.secndbtn {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .light,
  .revecs {
    padding: 0 0 53px 0;
  }


  .map-inline-list .maprow:last-child .map-content:AFTER {
    content: '';
    position: absolute;
    width: 2px;
    height: 61px;
    background: #2b2b2e;
    bottom: -62px;
  }

  .map-inline-list .maprow:last-child {
    position: relative;
  }

  .map-inline-list .maprow:last-child .map-content {
    margin-bottom: 30px;
  }

  .map-inline-list .maprow:last-child {
    padding: 0;
  }



  /*Mobile Menu*/
  .mobile-menu-icon {
    display: block;
  }

  .mobile-menu sidebar {
    width: 78%;
    right: -100%;
    display: block;
  }

  .mobile-menu.open-menu sidebar {
    right: 0;
    overflow-y: scroll;
    padding-bottom: 10px;
    overflow-x: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    height: 100%;

  }

  .mobile-menu sidebar li a {
    display: flex;
  }

  .mobile-menu sidebar li a {
    flex-direction: initial;
    justify-content: flex-start;
  }

  .mobile-menu sidebar li a img {
    width: 26px;
    margin-right: 8px;
    padding: 0;
  }

  sidebar .logsect ul li a {
    flex-direction: initial;
    width: 100%;
    justify-content: flex-start;
    padding: 8px;
    color: #fff;
    margin: 8px 0 0;

  }

  .mobile-menu sidebar .logsect ul li a:before {
    left: 0px;
  }

  ul.soclapps.fixdd {
    display: none;
  }

  .deskt {
    display: none;
  }

  .mobile-menu-icon,
  .mobile-menu {
    display: block;
  }

  .mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000e6;
    top: 0;
    right: -100%;
    transition: all 0.5s ease;
    z-index: 99999999999999999;
  }

  .mobile-menu.open-menu {
    right: 0;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
  }

  .rightnav {
    display: none;
  }

  .mobile-menu.open-menu .mobile-menu-container {
    right: 0;
    width: 100%;
  }

  .mobile-menu .rightnav {
    display: block;
    width: 100%;
  }

  .rightnav ul li.themee {
    display: flex;
    justify-content: space-between;
  }

  .rightnav ul li.brder {
    height: 1px;
    background: #373839;
    width: 100%;
    display: table;
    margin: 30px 0;
  }

  .rightnav ul {
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
  }

  .rightnav ul li {
    width: 100%;
    margin-bottom: 15px;
    line-height: 1;
    height: auto;
    padding: 0;
  }

  header.head .rightnav>ul {
    flex-wrap: wrap;
    flex-direction: column;
  }

  header.head .rightnav>ul li {
    width: 100%;
  }

  header.head .rightnav>ul li a.translator {
    justify-content: flex-start;
    color: #fff;
    font-weight: normal;
  }

  header.head .rightnav>ul li:nth-child(2n) {
    border: 0;
    width: 100%;
    margin-right: auto;
  }

  header.head .rightnav>ul li .btnn {
    margin-right: auto;
    margin-left: 0;
    width: 155px;
    align-items: center;
    justify-content: center;
    height: 60px;
  }

  header.head .rightnav ul li a.translator img {
    width: 26px;
    max-width: 26px;
    margin: 0;
  }

  header.head .rightnav ul li a.translator img {
    margin-right: 10px;
  }

  .desknone {
    display: block;
  }

  header.head .rightnav ul.icon {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  header.head .rightnav ul.icon li {
    width: auto;
    padding: 0;
    margin-bottom: 0;
  }

  header.head .rightnav ul.icon li:nth-child(2n) {
    margin: 0;
    border: 0;
  }

  header.head .rightnav ul.icon li:first-child {
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    margin-top: 0;
  }

  .menust {
    margin-top: 40px;
    padding: 10px;
  }

  .menust h2 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
  }

  header.head .rightnav ul.icon {
    margin-bottom: 20px;
  }

  .menust a {
    color: #9e9e9e;
    font-size: 14px;
  }

  header.head .rightnav>ul li:first-child {
    padding-top: 20px;
    padding-bottom: 21px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin-top: 30px;
  }

  /*Mobile Menu*/
  .rightnav {
    display: none;
  }

  .columns2 .col {
    width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }

  .headings h3 {
    position: initial;
  }

  .container {
    padding: 0 10px;
    box-sizing: border-box;
    max-width: initial;
  }

  header.head a.logodiv {
    width: 50%;
  }

  .columns2 .cols {
    width: 100%;
    flex: 0 0 100%;
  }

  .alphaimg img {
    position: initial;
    max-width: 100%;
  }

  section.alphasect .container {
    padding: 0;
  }

  section.alphasect .container .alphatext {
    padding: 10px;
    box-sizing: border-box;
  }

  .alphatext ul li {
    margin-bottom: 22px;
  }

  .partners-logo img {
    margin: 10px;
    width: 88px;
  }

  section.partners {
    padding: 15% 0;
  }

  .columns2.colwdth .col {
    width: 100%;
    flex: 0 0 100%;
  }

  .textpadl {
    padding: 0;
  }

  ul.tbtns a {
    margin-right: 10px;
    width: 160px !IMPORTANT;
    font-size: 14px;
    margin-left: 0;
  }

  ul.tbtns {
    width: 100%;
  }

  ul.tbtns li:last-child a {
    margin-right: 0;
  }

  .gaiatoksect {
    padding: 10% 0 0 0;
  }

  .gaiatoksect .columns2 .col {
    text-align: left;
  }

  .gaiatoksect .col img {
    margin: 0;
  }

  section.joinsect {
    padding-top: 10%;
  }

  .headings h2 {
    display: none;
  }

  section.partners:before {
    width: auto;
    height: auto;
  }

  section.playsect:before {
    width: auto;
    height: auto;
  }

  .joinsect .columns2.colwdth .col img {
    width: 100%;
    margin: 0;
  }


  footer .logosec {
    width: 100%;
    margin-bottom: 20px;

  }

  footer .logsect {
    width: 100%;
    margin-bottom: 30px;

  }

  footer .logosec p {
    padding-bottom: 0;
  }

  footer .soicallinks {
    width: 100%;
  }

  ul.icon.sclfixed {
    display: none;
  }

  .slider-left-img {
    position: absolute;
    bottom: -87px;
    width: 100%;
  }

  .slider-left-img img,
  .slider-left-img.right-side img {
    width: 58%;
  }

  .prowedicon {
    width: 100%;
    margin-top: 20px;
  }

  .heordetail {
    width: auto;
    height: auto;
    padding: 10px;
    margin: 12px;
    background-size: 100% 100%;

  }

  ul.tbtns {
    flex-wrap: wrap;
  }

  sidebar {
    display: none;
  }

  .maindiv {
    width: 100%;
    position: relative;
    z-index: 0;
  }

  .plybxes img {
    width: 100%;
  }

  ul.tbtns li {
    margin-bottom: 0px;
  }

  .monstor-content {
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0px 0 0 0;
    position: initial;
  }


  .monstor-content h2 {
    line-height: 60px;
    font-size: 48px;
    box-sizing: border-box;
    word-break: break-word;
    padding: 0 38px;

  }

  .monstor-content p {
    padding: 0 22px;
    line-height: 27px;
  }

  button.slick-arrow.slick-prev {
    top: 75px;
    right: initial;
    left: 4%;
    bottom: initial;

  }

  button.slick-arrow.slick-next {
    top: 75px;
    left: initial;
    right: 4%;
    bottom: initial;
  }


  .testimonal {
    padding: 11% 0 0 0;
  }

  .monstor-slide {
    padding: 0 0 34% 0;
  }

  .buycontent h3 {
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  sidebar .container {
    margin: 0;
  }

  .featuredimg {
    height: 217px;
    padding: 11px 8px 0 8px;
  }

  .blogcontent::after {
    opacity: 0.2;
  }

  section.dicsect video {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    object-fit: contain;
  }

  section.dicsect {
    height: auto;
  }

  .heordetail {
    width: 500px;
    height: auto;
    background-size: 100% 100%;
    padding: 18px;
    box-sizing: border-box;
  }

  .heordetail h1 {
    font-size: 30px;
  }

  .hero-sec video {
    object-fit: cover;
    height: 415px;
  }

  .btnn.prebtn,
  .btnn.secndbtn {
    width: 173px;
    font-size: 14px;
  }

  p {
    font-size: 15px;
  }

  .gaig-video {
    width: 90%;
    height: 560px;
  }

  .gaialand {
    overflow: visible;
  }

  section.gaiablog .btnn.secndbtn {
    width: 100%;
    background-size: contain;
  }

  section.gaiablog .serimg {
    flex: 0 0 48%;
  }

  #mainwrapper .tokendicsect .col.textpadl {
    padding: 0;
  }

  section.serlearn::before,
  section.buytoken:before,
  div#mainwrapper .giland:before,
  .blogloader:before {
    width: 68% !important;
    height: 29%;
    background-position: right;
  }

  .tokenrow {
    width: 100%;
  }

  section.gaiamap .container {
    display: none;
  }

  .mapimages img {
    width: 100%;
  }

  .buygaia {
    padding: 0;
  }

  .tokenrow .tokenitem img {
    width: 70%;
  }

  .buygaia:before,
  div#mainwrapper .giland:after {
    width: 40% !important;
    display: none;
  }


  section.ourteam:after,
  section.buytoken::after,
  .blogcontent::after {
    width: 100%;
  }

  section.about-tabs ul {
    width: 100%;
  }

  section.about-tabs .container {
    padding: 0px;
  }

  .aboutplay .abtplay {
    flex: 0 0 47% !important;
    height: 360px;
  }

  .plybxes p {
    font-size: 13px;
  }

  .serimg {
    flex: 0 0 31%;
  }

  .map-content p {
    font-size: 11px;
  }

  .dots {
    height: 8px;
  }

  .mapicon {
    margin: 0;
  }

  .simpldot .dots {
    margin: -45px 0 0 0;
  }

  .map-list:before {
    top: 110px;
    height: 89%;
  }

  div#mainwrapper .innerbanner .innercontent {
    width: 100%;
  }

  div#mainwrapper .innerbanner .innercontent p {
    color: #fff;
    font-size: 13px;
  }

  .innercontent h1 {
    font-size: 85px;
    line-height: 89px;
  }

  div#mainwrapper .innerbanner {
    height: 350px;
  }

  section.about-tabs ul li a {
    font-size: 17px;
    padding: 11px 11px;
  }

  .revecs .mapicon {
    margin: 0;
  }

  .teammeme {
    width: 30%;
  }

  .teaminfo h3 {
    font-size: 13px;
  }

  .teaminfo p {
    font-size: 13px;
  }

  .map-content:before {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 9px solid #2b2b2e;
    left: -9px;
  }

  .revecs .map-content::before {
    border-left: 9px solid #2b2b2e;
    right: -9px;
  }

  .maindiv {
    width: 100%;
    position: relative;
    z-index: -0;
  }

  ul.icon.sclfixed {
    display: none;
  }

  header.head {
    padding: 12px 20px;
  }

  .partners-logo img {
    width: 100px;
  }

  .joinsect .columns2.colwdth .col img {
    width: 510px;
  }

  footer .logosec {
    width: 50%;
  }

  footer .logsect {
    width: 50%;
  }

  footer .soicallinks {
    width: 50%;
  }

  .prowedicon {
    width: 50%;
  }

  /*Mobile Menu*/

  sidebar {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .mobile-menu sidebar {
    width: 78%;
    right: -100%;
    display: block;
  }

  .mobile-menu.open-menu sidebar {
    right: 0;
    top: 0;
    height: 100%;
  }

  .mobile-menu sidebar li a {
    display: flex;
    color: #fff;
  }

  .mobile-menu sidebar li a {
    flex-direction: initial;
    justify-content: flex-start;
  }

  .mobile-menu sidebar li a img {
    width: 26px;
    margin-right: 8px;
    padding: 0;
  }

  sidebar .logsect ul li a {
    flex-direction: initial;
    width: 100%;
    justify-content: flex-start;
    padding: 12px;
    color: #fff;

  }

  .mobile-menu sidebar .logsect ul li a:before {
    left: 0px;
  }

  ul.soclapps.fixdd {
    display: none;
  }

  .deskt {
    display: none;
  }

  .mobile-menu-icon,
  .mobile-menu {
    display: block;
  }

  .mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000e6;
    top: 0;
    right: -100%;
    transition: all 0.5s ease;
    z-index: 99999999999999999;
  }

  .mobile-menu.open-menu {
    right: 0;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
  }

  .rightnav {
    display: none;
  }

  .mobile-menu.open-menu .mobile-menu-container {
    right: 0;
    width: 100%;
  }

  .mobile-menu .rightnav {
    display: block;
    width: 100%;
  }

  .rightnav ul li.themee {
    display: flex;
    justify-content: space-between;
  }

  .rightnav ul li.brder {
    height: 1px;
    background: #373839;
    width: 100%;
    display: table;
    margin: 30px 0;
  }

  .rightnav ul {
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
  }

  .rightnav ul li {
    width: 100%;
    margin-bottom: 15px;
    line-height: 1;
    height: auto;
    padding: 0;
  }

  header.head .rightnav>ul {
    flex-wrap: wrap;
    flex-direction: column;
  }

  header.head .rightnav>ul li {
    width: 100%;
  }

  header.head .rightnav>ul li a.translator {
    justify-content: flex-start;
    color: #fff;
    font-weight: normal;
  }

  header.head .rightnav>ul li:nth-child(2n) {
    border: 0;
    width: 100%;
    margin-right: auto;
  }

  header.head .rightnav>ul li .btnn {
    margin-right: auto;
    margin-left: 0;
    width: 200px;
    align-items: center;
    justify-content: center;
  }

  header.head .rightnav ul li a.translator img {
    width: 26px;
    max-width: 26px;
    margin: 0;
  }

  header.head .rightnav ul li a.translator img {
    margin-right: 10px;
  }

  .desknone {
    display: block;
  }

  header.head .rightnav ul.icon {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  header.head .rightnav ul.icon li {
    width: auto;
    padding: 0;
    margin-bottom: 0;
  }

  header.head .rightnav ul.icon li:nth-child(2n) {
    margin: 0;
    border: 0;
  }

  header.head .rightnav ul.icon li:first-child {
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    margin-top: 0;
  }

  .menust {
    margin-top: 70px;
    padding: 10px;
  }

  .menust h2 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
  }

  header.head .rightnav ul.icon {
    margin-bottom: 20px;
  }

  .menust a {
    color: #9e9e9e;
    font-size: 14px;
  }

  header.head .rightnav>ul li:first-child {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin-top: 30px;
  }

  /*Mobile Menu*/
  .rightnav {
    display: none;
  }

  .monstor-content {
    left: 0%;
    height: 100%;
    width: 50%;
  }

  .monstor-content h3 {
    FONT-SIZE: 20px;
  }

  .monstor-content h2 {
    line-height: 50px;
    font-size: 50px;
    padding: 3.5% 0 12% 0;
  }

  .monstor-content p {
    padding: 0 16%;
    font-size: 13px;
    line-height: 27px;
  }

  button.slick-arrow.slick-prev {
    top: -172px;
    right: 80px;
  }

  button.slick-arrow.slick-next {
    top: -172px;
    left: 80px;
  }

  .slider-left-img img,
  .slider-left-img.right-side img {
    width: 80%;
  }

  .testimonal {
    padding: 11% 0 0 0;
  }

  .monstor-slide {
    padding-top: 14%;
  }

  .container {
    max-width: 100%;
    padding: 18px;
    box-sizing: border-box;
  }

  .headings h2 {
    font-size: 162px;
  }

  .plybxes img {
    width: 100%;
  }

  .plybxes {
    padding: 20px;
    text-align: center;
  }

  .columns2 .col {
    flex: 0 0 48%;
  }

  section.playsect:before,
  section.playsect:after {
    width: 500px;
    height: 310px;
    background-size: cover;
  }

  section.partners:before,
  section.partners:after {
    height: 300px;
    width: 500px;
    background-size: cover;
  }

  ul.tbtns {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }


  ul.tbtns li a {
    margin: initial;
    margin-bottom: 12px;
    margin-right: 12px;
  }

  .blogcontent::after {
    background-size: cover;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .blogcontent::after {
    background-size: cover;
  }

  .featuredimg {
    height: auto;
  }

  section.about-tabs ul {
    width: 90%;
  }

  div#mainwrapper .innerbanner .innercontent {
    width: 80%;
  }

  div#mainwrapper .innerbanner .innercontent p {
    color: #fff;
  }

  section.about-tabs .container {
    padding: 0px;
  }

  .aboutplay .abtplay {
    flex: 0 0 31.3%;
  }

  .plybxes p {
    font-size: 13px;
  }

  .serimg {
    flex: 0 0 31%;
  }

  .map-content p {
    font-size: 11px;
  }

  .dots {
    height: 10px;
  }

  .mapicon {
    margin: 0;
  }

  .map-list:before {
    top: -70px;
    height: 97%;
  }

  .monstor-content {
    height: 100%;
  }

  .monstor-content h3 {
    FONT-SIZE: 30PX;
  }

  .monstor-content h2 {
    line-height: 50px;
    font-size: 70px;
    padding: 3.5% 0 11% 0;
  }

  .monstor-content p {
    padding: 0 16%;
    font-size: 16px;
  }

  button.slick-arrow.slick-prev {
    top: 80.5%;
    right: 4%;
  }

  button.slick-arrow.slick-next {
    top: 80.5%;
    left: 6%;
  }

  .slider-left-img img,
  .slider-left-img.right-side img {
    width: 80%;
  }

  .testimonal {
    padding: 11% 0 0 0;
  }

  .monstor-slide {
    padding-top: 4%;
  }

  section.playsect .col.plybxes {
    height: 405px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  sidebar .logsect ul li a {
    font-size: 11px;
    width: 94%;
  }

  .container {
    padding: 0 3%;
    width: auto;
    box-sizing: border-box;
  }

  section.playsect .col.plybxes {
    flex: 0 0 46%;
  }

  sidebar .logsect ul li img {
    max-width: 20px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .blogcontent::after {
    background-size: cover;
  }

  section.playsect .col.plybxes {
    width: 50%;
    flex: 0 0 49%;
    height: 470px;
  }

  button.slick-arrow.slick-next {
    left: 50px;
    top: 0;
  }

  button.slick-arrow.slick-prev {
    top: 0;
  }

  .monstor-content {
    height: 80%;
  }

  .monstor-content h2 {
    font-size: 121px;
    line-height: 150px;
  }

  .container {
    max-width: 1250px;
  }

  #mainwrapper .gaiabg .col.textpadl {
    padding: 0 40px;
  }

  section.serlearn::before,
  section.buytoken:before,
  div#mainwrapper .giland:before,
  .blogloader:before {
    width: 50% !important;
    height: 36%;
  }

  section.roadmap::before,
  .buygaia:before,
  div#mainwrapper .giland:after {
    width: 26% !important;
    background-size: contain;
  }

  .aboutplay .abtplay {
    width: 30%;
    flex: 0 0 31%;
  }

  .plybxes img {
    width: 100%;
  }

  .giland .columns2~p {
    padding: 0 20px;
  }

  section.zonevideo p {
    padding: 50px 50px;
  }

  section.gaiablog .bloglist.columns2 {
    width: 96%;
    margin: 0 auto;
  }

  #mainwrapper .tokendicsect .col.textpadl {
    padding: 0 40px;
  }

  .buycontent {
    width: 90%;
    margin: 0 auto;
  }

  section.gaiamap .container {
    padding: 0 40px;
    box-sizing: border-box;
  }

  .mapimages img {
    width: 100%;
  }

  .map-list {
    padding: 40px 40px;
  }

  .dots {
    height: 12px;
  }

  div#mainwrapper .aboutplay .abtplay {
    flex: 0 0 31%;
    height: 390px;
  }

  .mapicon {
    margin: 0 -10px 0 10px;
  }

  footer {
    position: relative;
    z-index: 9;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {

  section.gaiablog .serimg,
  .columns2 .col {
    flex: 0 0 48%;
  }

  section.dicsect ul.tbtns {
    width: 51%;
  }

  .aboutplay .abtplay {
    flex: 0 0 47%;
  }

  .tokenrow .tokenitem {
    width: 50%;
    flex: 0 0 46%;
  }

  .dots {
    width: 1%;
  }
}

@media all and (max-width: 1700px) and (min-width: 1501px) {
  sidebar .logsect ul li a {
    font-size: 10px;
  }

  sidebar .logsect ul li img {
    max-width: 21px;
  }
}